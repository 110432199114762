import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { Spinner } from '@shopify/polaris'
import { Modal, Typography, Flex, Spin, Space } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Text, Link } = Typography

const AIHelperModal = ({ active, setActive }) => {
  const [isLoading, setIsLoading] = useState(false)

  // todo 替換成真實的商品分類資料
  // const jsonData_example = [
  //   { id: 1, name: 'John', age: 30 },
  //   { id: 2, name: 'Jane', age: 25 },
  //   { id: 3, name: 'Mike', age: 28 }
  // ]

  const jsonData = [
    {
      item_number: '89638',
      item_desc: '模型現貨88折~《 重塑火星收納大盒 Big Box 》(內含地形板塊模型) (不含遊戲本體與擴充)',
      inventory_item_id: '89638',
      item_publish_date: '2024-06-27',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: '公仔、模型',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '89631',
      item_desc: '新版現貨88折~桌遊版的絕地救援——《 重塑火星Terraforming Mars 》(繁中版)',
      inventory_item_id: '89631',
      item_publish_date: '2024-06-27',
      profit_center: 'panmarket.asia',
      item_copy: '電玩遊戲',
      segment1: '桌遊',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '89288',
      item_desc: '【限時限量】化石地質年代玻璃把手高燒杯',
      inventory_item_id: '89288',
      item_publish_date: '2024-01-03',
      profit_center: 'panmarket.asia',
      item_copy: '水壺/杯子',
      segment1: '杯子/馬克杯',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '89157',
      item_desc: '限時82折~《 阿納克遺跡 Lost Ruins of Arnak 》(繁體中文版)',
      inventory_item_id: '89157',
      item_publish_date: '2023-11-07',
      profit_center: 'panmarket.asia',
      item_copy: '電玩遊戲',
      segment1: '桌遊',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '89150',
      item_desc: '世界最古老的桌遊——《 塞尼特棋 SENET 》(繁體中文版)',
      inventory_item_id: '89150',
      item_publish_date: '2023-11-07',
      profit_center: 'panmarket.asia',
      item_copy: '電玩遊戲',
      segment1: '桌遊',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '89028',
      item_desc: '興大研發｜台灣特有種土肉桂茶(4盒)',
      inventory_item_id: '89028',
      item_publish_date: '2023-09-16',
      profit_center: 'panmarket.asia',
      item_copy: '美食、伴手禮',
      segment1: '茶葉',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88834',
      item_desc: 'ACUTER 【小藍馬80】80mm 天文望遠鏡 微動雲台增高穩定版腳架組合',
      inventory_item_id: '88834',
      item_publish_date: '2023-07-06',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: '其他',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88820',
      item_desc: 'ACUTER【迷你馬】60mm 迷你馬多功能生活望遠鏡 全新大禮盒版',
      inventory_item_id: '88820',
      item_publish_date: '2023-07-03',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88703',
      item_desc: 'QUALY｜海潮之生-磁鐵(兩入組)',
      inventory_item_id: '88703',
      item_publish_date: '2023-06-16',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '傢飾',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88675',
      item_desc: 'QUALY｜拯救海龜-開瓶器',
      inventory_item_id: '88675',
      item_publish_date: '2023-06-15',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '餐廚用品',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88609',
      item_desc: '75折~塵之書三部曲І+II（野美人號+祕密聯邦）',
      inventory_item_id: '88609',
      item_publish_date: '2023-06-07',
      profit_center: 'panmarket.asia',
      item_copy: '書籍及雜誌期刊',
      segment1: '文學小說',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88588',
      item_desc: '75折~【迷霧之子】系列—執法鎔金（自影、悼環、謎金，全三冊）',
      inventory_item_id: '88588',
      item_publish_date: '2023-06-05',
      profit_center: 'panmarket.asia',
      item_copy: '書籍及雜誌期刊',
      segment1: '文學小說',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88584',
      item_desc: '75折~【迷霧之子】十周年紀念典藏限量精裝版套書(3本)',
      inventory_item_id: '88584',
      item_publish_date: '2023-06-05',
      profit_center: 'panmarket.asia',
      item_copy: '書籍及雜誌期刊',
      segment1: '文學小說',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88578',
      item_desc: '75折~三體系列套書 I-III (燙銀簽名版/3冊合售)',
      inventory_item_id: '88578',
      item_publish_date: '2023-06-05',
      profit_center: 'panmarket.asia',
      item_copy: '書籍及雜誌期刊',
      segment1: '文學小說',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88577',
      item_desc: '75折~亞特蘭提斯進化三部曲',
      inventory_item_id: '88577',
      item_publish_date: '2023-06-05',
      profit_center: 'panmarket.asia',
      item_copy: '書籍及雜誌期刊',
      segment1: '文學小說',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88575',
      item_desc: '79折~潰雪Snow Crash【30週年紀念版+作者新版序】(含運)',
      inventory_item_id: '88575',
      item_publish_date: '2023-06-05',
      profit_center: 'panmarket.asia',
      item_copy: '書籍及雜誌期刊',
      segment1: '文學小說',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88612',
      item_desc: '75折~營繕師異譚І+II',
      inventory_item_id: '88612',
      item_publish_date: '2023-06-04',
      profit_center: 'panmarket.asia',
      item_copy: '書籍及雜誌期刊',
      segment1: '輕小說',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88345',
      item_desc: '極地特快車 | Polar Steel Express',
      inventory_item_id: '88345',
      item_publish_date: '2023-05-21',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: '玩具',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88309',
      item_desc: '水手之光燈塔 | Sailor’s Companion Lighthouse',
      inventory_item_id: '88309',
      item_publish_date: '2023-05-21',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '燈具',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88264',
      item_desc: '奮起直升機 Lifting Spirit Helicopter',
      inventory_item_id: '88264',
      item_publish_date: '2023-05-21',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: '玩具',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88302',
      item_desc: '魅影潛艇．鸚鵡螺號 Elusive Nautilus Submarine',
      inventory_item_id: '88302',
      item_publish_date: '2023-05-21',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88307',
      item_desc: '永遠的道格拉斯DC-3 Remarkable Douglas',
      inventory_item_id: '88307',
      item_publish_date: '2023-05-21',
      profit_center: 'panmarket.asia',
      item_copy: '書籍及雜誌期刊',
      segment1: '歷史人文書籍',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88331',
      item_desc: '烏克蘭之夢 MRIYA – 超級運輸機 | Ukrainian Dream Mechanical Aircraft',
      inventory_item_id: '88331',
      item_publish_date: '2023-05-21',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88014',
      item_desc: 'ZUUTii｜自動開蓋油醋瓶(二入組)(奶油白芝麻灰售罄)',
      inventory_item_id: '88014',
      item_publish_date: '2023-05-11',
      profit_center: 'panmarket.asia',
      item_copy: '餐廚用品',
      segment1: '料理用具',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88074',
      item_desc: 'ZUUTii｜玻璃調味料罐(二入組)',
      inventory_item_id: '88074',
      item_publish_date: '2023-05-11',
      profit_center: 'panmarket.asia',
      item_copy: '餐廚用品',
      segment1: '保鮮盒/便當盒',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88009',
      item_desc: 'ZUUTii｜多功能料理工具六件組',
      inventory_item_id: '88009',
      item_publish_date: '2023-05-11',
      profit_center: 'panmarket.asia',
      item_copy: '餐廚用品',
      segment1: '料理用具',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88038',
      item_desc: 'ZUUTii｜按壓式防潮儲米箱',
      inventory_item_id: '88038',
      item_publish_date: '2023-05-11',
      profit_center: 'panmarket.asia',
      item_copy: '餐廚用品',
      segment1: '保鮮盒/便當盒',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88131',
      item_desc: 'ZUUTii｜不鏽鋼雙面砧板',
      inventory_item_id: '88131',
      item_publish_date: '2023-05-11',
      profit_center: 'panmarket.asia',
      item_copy: '餐廚用品',
      segment1: '刀具砧板配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88189',
      item_desc: 'ZUUTii｜不沾煎炒鍋-28cm',
      inventory_item_id: '88189',
      item_publish_date: '2023-05-11',
      profit_center: 'panmarket.asia',
      item_copy: '餐廚用品',
      segment1: '鍋具',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88182',
      item_desc: 'ZUUTii｜不沾煎炒鍋-16cm',
      inventory_item_id: '88182',
      item_publish_date: '2023-05-11',
      profit_center: 'panmarket.asia',
      item_copy: '餐廚用品',
      segment1: '鍋具',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87965',
      item_desc: 'Matador《鬥牛士Laptop Base Layer 防水輕量筆記型電腦內膽收納包》',
      inventory_item_id: '87965',
      item_publish_date: '2023-05-09',
      profit_center: 'panmarket.asia',
      item_copy: '3C與筆電',
      segment1: '筆記型電腦',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87474',
      item_desc: 'Matador《鬥牛士 Camera Base Layer 相機防水保護包》',
      inventory_item_id: '87474',
      item_publish_date: '2023-05-09',
      profit_center: 'panmarket.asia',
      item_copy: '3C與筆電',
      segment1: '相機周邊配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87261',
      item_desc: 'Matador《鬥牛士FreeRain 22L防水捲口輕量背包》',
      inventory_item_id: '87261',
      item_publish_date: '2023-05-09',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行包款、旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87931',
      item_desc: 'Matador《鬥牛士On-Grid 25L防潑水輕量旅行袋》',
      inventory_item_id: '87931',
      item_publish_date: '2023-05-09',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行包款、旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87879',
      item_desc: 'Matador《鬥牛士 On-Grid 2L防水旅行腰包 - 黑色》',
      inventory_item_id: '87879',
      item_publish_date: '2023-05-09',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行包款、旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87904',
      item_desc: 'Matador《鬥牛士 Pocket Blanket mini 3.0 戶外迷你口袋型野餐墊 1-2人用》',
      inventory_item_id: '87904',
      item_publish_date: '2023-05-09',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '戶外休閒運動',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87861',
      item_desc: 'Matador《鬥牛士 NanoDry Towel 二代 奈米纖維速乾毛巾(S)》',
      inventory_item_id: '87861',
      item_publish_date: '2023-05-08',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87844',
      item_desc: 'Matador《鬥牛士 NanoDry Towel 二代 奈米纖維速乾毛巾(L)》',
      inventory_item_id: '87844',
      item_publish_date: '2023-05-08',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87822',
      item_desc: 'Matador《鬥牛士  DROPLET 二代 2.5L防水水滴袋》',
      inventory_item_id: '87822',
      item_publish_date: '2023-05-08',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87765',
      item_desc: '新品 moorigin《紙莎草耳環 XS》',
      inventory_item_id: '87765',
      item_publish_date: '2023-05-06',
      profit_center: 'panmarket.asia',
      item_copy: '配件',
      segment1: '耳環',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87700',
      item_desc: '新品 moorigin《蕈菇耳環 S》',
      inventory_item_id: '87700',
      item_publish_date: '2023-05-06',
      profit_center: 'panmarket.asia',
      item_copy: '配件',
      segment1: '耳環',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87699',
      item_desc: '新品 moorigin《銀杏紛飛項鍊 S》',
      inventory_item_id: '87699',
      item_publish_date: '2023-05-06',
      profit_center: 'panmarket.asia',
      item_copy: '配件',
      segment1: '項鍊',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87695',
      item_desc: '新品 moorigin《紙莎草胸針 S》',
      inventory_item_id: '87695',
      item_publish_date: '2023-05-06',
      profit_center: 'panmarket.asia',
      item_copy: '配件',
      segment1: '其他',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87689',
      item_desc: '新品 moorigin《銀杏紛飛胸針-s》',
      inventory_item_id: '87689',
      item_publish_date: '2023-05-06',
      profit_center: 'panmarket.asia',
      item_copy: '配件',
      segment1: '其他',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87688',
      item_desc: '新品 moorigin《酢漿草項鍊 XS》',
      inventory_item_id: '87688',
      item_publish_date: '2023-05-06',
      profit_center: 'panmarket.asia',
      item_copy: '配件',
      segment1: '項鍊',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87444',
      item_desc: 'Matador《鬥牛士 Beast 28L進階級輕量摺疊防潑水背包 》',
      inventory_item_id: '87444',
      item_publish_date: '2023-04-29',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行包款、旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87411',
      item_desc: 'Matador《鬥牛士 Beast 18L 進階級輕量摺疊防潑水背包 》',
      inventory_item_id: '87411',
      item_publish_date: '2023-04-29',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行包款、旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87378',
      item_desc: 'Matador《鬥牛士 Freefly 30L防水摺疊旅行袋》',
      inventory_item_id: '87378',
      item_publish_date: '2023-04-28',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行包款、旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87360',
      item_desc: 'Matador《鬥牛士 FreeRain 防水輕量捲口拉鏈腰包》',
      inventory_item_id: '87360',
      item_publish_date: '2023-04-28',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87335',
      item_desc: 'Matador《鬥牛士FreeRain 28L防水捲口輕量背包》',
      inventory_item_id: '87335',
      item_publish_date: '2023-04-28',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '旅行包款、旅行配件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87314',
      item_desc: '79折~《被蒙蔽的視野: 科學全球發展史的真貌》',
      inventory_item_id: '87314',
      item_publish_date: '2023-04-27',
      profit_center: 'panmarket.asia',
      item_copy: '書籍及雜誌期刊',
      segment1: '自然科普書籍',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87234',
      item_desc: '【牛津棉布】雙髻鯊立體造型零錢包',
      inventory_item_id: '87234',
      item_publish_date: '2023-04-21',
      profit_center: 'panmarket.asia',
      item_copy: '包包',
      segment1: '錢包/皮件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87226',
      item_desc: '【牛津棉布】虎鯊立體造型零錢包',
      inventory_item_id: '87226',
      item_publish_date: '2023-04-21',
      profit_center: 'panmarket.asia',
      item_copy: '配件',
      segment1: '其他',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87242',
      item_desc: '【牛津棉布】鯨鯊立體造型零錢包',
      inventory_item_id: '87242',
      item_publish_date: '2023-04-21',
      profit_center: 'panmarket.asia',
      item_copy: '配件',
      segment1: '其他',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87217',
      item_desc: '【牛津棉布】烏翅真鯊立體造型零錢包',
      inventory_item_id: '87217',
      item_publish_date: '2023-04-21',
      profit_center: 'panmarket.asia',
      item_copy: '包包',
      segment1: '錢包/皮件',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87182',
      item_desc: '【純棉帆布】 綠蠵龜造型立體肩背包',
      inventory_item_id: '87182',
      item_publish_date: '2023-04-21',
      profit_center: 'panmarket.asia',
      item_copy: '包包',
      segment1: '肩背包',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87194',
      item_desc: '【純棉帆布】鋸鰩立體造型肩背包#寶寶藍',
      inventory_item_id: '87194',
      item_publish_date: '2023-04-21',
      profit_center: 'panmarket.asia',
      item_copy: '包包',
      segment1: '肩背包',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87181',
      item_desc: '【純棉帆布】鋸鰩立體造型肩背包#暗夜藍',
      inventory_item_id: '87181',
      item_publish_date: '2023-04-21',
      profit_center: 'panmarket.asia',
      item_copy: '包包',
      segment1: '肩背包',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87068',
      item_desc: 'QUALY｜鯨鯊手工陶瓷杯',
      inventory_item_id: '87068',
      item_publish_date: '2023-04-16',
      profit_center: 'panmarket.asia',
      item_copy: '水壺/杯子',
      segment1: '杯子/馬克杯',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '82806',
      item_desc: '海底生物分佈圖玻璃把手高燒杯',
      inventory_item_id: '82806',
      item_publish_date: '2023-04-13',
      profit_center: 'panmarket.asia',
      item_copy: '水壺/杯子',
      segment1: '杯子/馬克杯',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '88492',
      item_desc: 'QUALY｜珊瑚玻璃杯300ml',
      inventory_item_id: '88492',
      item_publish_date: '2023-03-29',
      profit_center: 'panmarket.asia',
      item_copy: '水壺/杯子',
      segment1: '杯子/馬克杯',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '81417',
      item_desc: 'QUALY 醉愛北極熊酒瓶塞',
      inventory_item_id: '81417',
      item_publish_date: '2023-03-25',
      profit_center: 'panmarket.asia',
      item_copy: '餐廚用品',
      segment1: '酒類用品',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '81411',
      item_desc: 'QUALY 北極熊衛生紙盒',
      inventory_item_id: '81411',
      item_publish_date: '2023-03-25',
      profit_center: 'panmarket.asia',
      item_copy: '日用品',
      segment1: '衛生紙',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '76402',
      item_desc: 'QUALY｜冰原企鵝–皂液罐',
      inventory_item_id: '76402',
      item_publish_date: '2023-03-25',
      profit_center: 'panmarket.asia',
      item_copy: '日用品',
      segment1: '家用清潔劑',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '76416',
      item_desc: 'QUALY｜冰原企鵝–收納罐（黑）',
      inventory_item_id: '76416',
      item_publish_date: '2023-03-25',
      profit_center: 'panmarket.asia',
      item_copy: '收納',
      segment1: '收納盒',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '76259',
      item_desc: 'QUALY 北極熊收納罐',
      inventory_item_id: '76259',
      item_publish_date: '2023-03-25',
      profit_center: 'panmarket.asia',
      item_copy: '收納',
      segment1: '收納盒',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '76353',
      item_desc: 'QUALY｜鯨魚開瓶器',
      inventory_item_id: '76353',
      item_publish_date: '2023-03-25',
      profit_center: 'panmarket.asia',
      item_copy: '餐廚用品',
      segment1: '酒類用品',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86909',
      item_desc: 'QUALY｜愛地球杯墊二入組',
      inventory_item_id: '86909',
      item_publish_date: '2023-03-25',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '餐廚用品',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '83878',
      item_desc: 'QUALY｜拯救海洋-磁鐵(六入)',
      inventory_item_id: '83878',
      item_publish_date: '2023-03-20',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '居家香氛',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '76298',
      item_desc: 'QUALY｜熱帶海洋–磁鐵（6入）',
      inventory_item_id: '76298',
      item_publish_date: '2023-03-20',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '傢飾',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87130',
      item_desc: 'QUALY｜拯救海龜-杯墊',
      inventory_item_id: '87130',
      item_publish_date: '2023-03-20',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '居家香氛',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '75748',
      item_desc: 'QUALY ｜鯨魚置物盒',
      inventory_item_id: '75748',
      item_publish_date: '2023-03-20',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '收納',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87141',
      item_desc: 'QUALY｜拯救海龜-牙刷架',
      inventory_item_id: '87141',
      item_publish_date: '2023-03-18',
      profit_center: 'panmarket.asia',
      item_copy: '日用品',
      segment1: '清潔刷',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87135',
      item_desc: 'QUALY｜拯救海龜-磁鐵',
      inventory_item_id: '87135',
      item_publish_date: '2023-03-18',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '傢飾',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '1679',
      item_desc: 'SkyWatcher《BK707AZ2折射式天文望遠鏡》',
      inventory_item_id: '1679',
      item_publish_date: '2023-03-18',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: '其他',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '3171',
      item_desc: 'SkyWatcher《 BK909AZ3天文望遠鏡》',
      inventory_item_id: '3171',
      item_publish_date: '2023-03-18',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: '其他',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86898',
      item_desc: 'QUALY｜拯救海龜-香皂盤',
      inventory_item_id: '86898',
      item_publish_date: '2023-03-18',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '浴室',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86934',
      item_desc: 'QUALY｜手提式置物箱7L',
      inventory_item_id: '86934',
      item_publish_date: '2023-03-17',
      profit_center: 'panmarket.asia',
      item_copy: '收納',
      segment1: '收納箱',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86929',
      item_desc: 'QUALY｜手提式置物箱3.5L',
      inventory_item_id: '86929',
      item_publish_date: '2023-03-17',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '收納',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '76363',
      item_desc: 'QUALY｜鯊魚磁鐵',
      inventory_item_id: '76363',
      item_publish_date: '2023-03-17',
      profit_center: 'panmarket.asia',
      item_copy: '居家生活',
      segment1: '文具',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '87142',
      item_desc: 'QUALY｜鶴大師功夫筷',
      inventory_item_id: '87142',
      item_publish_date: '2023-03-17',
      profit_center: 'panmarket.asia',
      item_copy: '餐廚用品',
      segment1: '碗盤餐具',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '3189',
      item_desc: '《BKP15075牛頓反射式天文望遠鏡+NEQ3赤道儀腳架》',
      inventory_item_id: '3189',
      item_publish_date: '2023-03-16',
      profit_center: 'panmarket.asia',
      item_copy: '戶外/旅行',
      segment1: '戶外休閒運動',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86870',
      item_desc: 'SkyWatcher《 BK102500  自動尋星多功能望遠鏡》',
      inventory_item_id: '86870',
      item_publish_date: '2023-03-16',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: '其他',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '81423',
      item_desc: 'QUALY 北極熊迴紋針組',
      inventory_item_id: '81423',
      item_publish_date: '2023-03-15',
      profit_center: 'panmarket.asia',
      item_copy: '文創商品',
      segment1: '文具',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '76425',
      item_desc: 'QUALY｜拯救鯨魚–收納盒（灰）',
      inventory_item_id: '76425',
      item_publish_date: '2023-03-15',
      profit_center: 'panmarket.asia',
      item_copy: '收納',
      segment1: '收納盒',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86207',
      item_desc: 'QUALY 鯨魚儲物箱(3.5L)',
      inventory_item_id: '86207',
      item_publish_date: '2023-03-15',
      profit_center: 'panmarket.asia',
      item_copy: '收納',
      segment1: '收納箱',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '71660',
      item_desc: 'Meade《EclipseView反射式天文望遠鏡》含太陽濾鏡',
      inventory_item_id: '71660',
      item_publish_date: '2023-03-14',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: '其他',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '79968',
      item_desc: '德teifoc益智磚塊建築玩具 TEI4060 (小倉庫)',
      inventory_item_id: '79968',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '56622',
      item_desc: '德teifoc益智磚塊建築玩具TEI4040 (風車)',
      inventory_item_id: '56622',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '85043',
      item_desc: '德teifoc益智磚塊建築玩具- TEI4500(夏季別墅 )',
      inventory_item_id: '85043',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '56467',
      item_desc: '德國teifoc 益智磚塊建築玩具TEI51(鄉村農舍)',
      inventory_item_id: '56467',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86730',
      item_desc: '德teifoc益智磚塊建築玩具- TEI4010(庭院小平房)',
      inventory_item_id: '86730',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '56638',
      item_desc: '德teifoc益智磚塊建築玩具TEI4030 (鄉間水庫)',
      inventory_item_id: '56638',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '56634',
      item_desc: '德teifoc益智磚塊建築玩具TEI4050 (迷你教堂)',
      inventory_item_id: '56634',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86707',
      item_desc: '德teifoc益智磚塊建築玩具- TEI4070(造型筆筒)',
      inventory_item_id: '86707',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86712',
      item_desc: '德teifoc益智磚塊建築玩具- TEI1025(歐式小水井)',
      inventory_item_id: '86712',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '56812',
      item_desc: '德teifoc益智磚塊建築玩具TEI4300 (溫馨瓦房)',
      inventory_item_id: '56812',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86715',
      item_desc: '德teifoc益智磚塊建築玩具- TEI4310(德國村莊)',
      inventory_item_id: '86715',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86719',
      item_desc: '德teifoc益智磚塊建築玩具- TEI4950(大型馬廄)',
      inventory_item_id: '86719',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    },
    {
      item_number: '86710',
      item_desc: '德teifoc益智磚塊建築玩具- TEI9010(我的小農場)',
      inventory_item_id: '86710',
      item_publish_date: '2023-03-12',
      profit_center: 'panmarket.asia',
      item_copy: '娛樂、收藏',
      segment1: 'LEGO與積木',
      segment2: '未分類',
      segment3: '未分類'
    }
  ]

  const convertToCSV = (data) => {
    const keys = Object.keys(data[0]) // 取得欄位名稱
    const header = keys.join(',') // 組成 CSV 的標題列
    const rows = data.map((row) =>
      keys.map((key) => `'${row[key]}'`).join(',')
    ) // 每列轉成 CSV 格式
    return [header, ...rows].join('\n') // 合併標題與資料列
  }

  const downloadCSV = () => {
    const csvData = convertToCSV(jsonData)
    const blob = new Blob([csvData], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.download = 'ai_data.csv' // 設定下載檔案名稱
    link.click()

    URL.revokeObjectURL(url)
  }

  useEffect(() => {
    if (active) {
      setIsLoading(true)
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, 8000)
      return () => clearTimeout(timer)
    }
  }, [active])

  return (
    <Modal
      title='AI小助手幫忙分類'
      open={active}
      // onOk={onSave}
      onCancel={() => setActive(false)}
      centered
      width={300}
      // okText={t('save_button')}
      // cancelText={t('cancel_button')}
      onOkType='default'
      okButtonProps={{ style: { background: '#1890ff', borderColor: '#1890ff' } }}
    >
      <div className='max-h-[500px] overflow-y-auto mt-12 mb-8 w-full justify-center'>
        {isLoading
          ? (
            <>
              <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                <Text strong>請稍待，商品分類分析中...</Text>
                <Flex align='center' gap='middle' justify='center'>
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                </Flex>
              </Space>
            </>)
          : (
            <>
              <Space direction='vertical' size='middle' style={{ display: 'flex' }}>
                <Text strong>請下載商品分類表：</Text>
                <Text>
                  <Link onClick={downloadCSV}>AI商品分類表</Link>
                </Text>
              </Space>
            </>)
          }
      </div>
    </Modal>
  )
}

AIHelperModal.propTypes = {
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired
}

export default AIHelperModal
